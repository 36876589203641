
.ant-layout-sider {
  height: 100vh;
  background-color: rgb(4, 81, 64);
  flex: 0 0 200px !important;
  max-width: 220px !important;
  min-width: 220px !important;
  width: 220px !important;
}
.ant-layout-sider-collapsed {
  height: 100vh;
  flex: 0 0 55px !important;
  max-width: 55px !important;
  min-width: 55px !important;
  width: 55px !important;
}

#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }