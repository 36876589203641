// .ant-layout-sider-collapsed {
//     height: 100vh;
//     flex: 0 0 55px !important;
//     max-width: 55px !important;
//     min-width: 55px !important;
//     width: 55px !important;
// }

.ant-layout-sider-children {
    .ant-menu-item {
        height: 35px;
        line-height: 23px;
        list-style-position: inside;
        list-style-type: disc;
        padding-left: 14px !important;
        border-radius: 4px;
        margin: 8px auto;

        &:hover {
            color: white !important;
        }

        .ant-menu-title-content {
            flex: auto;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.3px;
            color: white;
            text-shadow: 0 0 #53575e;
            // color: #53575e;
            // text-shadow: 0 0 #53575e;
        }

        svg {
            // color: #7c828d !important;
            color: white !important;
        }
    }

    .ant-menu-submenu {
        .ant-menu-item {
            padding-left: 40px !important;
        }

        .ant-menu-submenu-title {
            padding-left: 14px !important;
            border-radius: 4px;
            height: 35px;
            line-height: 23px;

            .ant-menu-title-content {
                flex: auto;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0.3px;
                color: white;
                text-shadow: 0 0 #53575e;
                // color: #53575e;
                // text-shadow: 0 0 #53575e;
            }

            svg {
                // color: #7c828d !important;
                color: white !important;
            }

            .ant-menu-sub.ant-menu-inline {
                background: rgba(0, 0, 0, 0.02);
            }


            .ant-menu-submenu-active {
                // background-color: #A0E82F !important;
            }
        }
    }

    .ant-menu-submenu-arrow {
        color: white !important;
    }

    .ant-menu-submenu-active {
        // background-color: #A0E82F !important;
    }

    .ant-menu-item-active {
        background-color: #A0E82F !important;

        .ant-menu-title-content,
        svg {
            color: #045140 !important;
        }
    }

    .ant-menu-item-selected {
        background-color: #A0E82F !important;

        .ant-menu-title-content,
        svg {
            color: #045140 !important;
        }

    }
}

.role-permissions {
    .ant-btn-primary {
        background-color: #045140 !important;
        color: #fff;
        align-items: center;
        vertical-align: middle;
        display: flex !important;

        &:disabled {
            background-color: #b6b6b6 !important;
            color: rgb(117, 117, 117);
            align-items: center;
        }
    }

    .ant-transfer-list {
        width: 47% !important;
        height: 70vh;
    }
}


:where(.css-dev-only-do-not-override-10ed4xt).ant-space-align-baseline {
    align-items: center;
}

label {
    color: #959595 !important;
}

//  -------- table style start  --------

.responsiveTable {

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border: 1px solid #eff2f7;
        // text-align: left;
    }

    td,
    th {
        padding: 0.7rem 0 0.7rem 1rem;
    }
}

.table-striped>tbody>tr:nth-of-type(odd) {
    // background-color: #f7f7f7;
    background-color: #f9f9f9;
}

.table-hover {
    tr {
        &:hover {
            background-color: #f7f7f7;
        }
    }
}

//  -------- table style end  --------


.ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 2px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
}

.custom_form_style {

    .search_pinfl_box {
        background-color: #fafafa;
        border: 1px solid #8ec5ef;
        padding: 8px 20px 8px 8px;
        border-radius: 4px;
        margin-bottom: 14px;
    }

    .border_col {
        border: 1px solid #e7eaee;
        border-radius: 4px;
        padding: 16px 8px;
        margin: 10px 0;
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 166px;
        max-width: 150px;
    }

    .ant-upload-list-picture-card-container {
        display: inline-block;
        width: 100%;
        height: 166px;
        max-width: 150px;
        margin: 0 8px 8px 0;
    }

    .ant-input {
        padding: 4px 10px;
        color: #6e6d7a !important;
        font-size: 14px !important;
        line-height: 1.5715;
        background-color: #fff;
        // border: 1px solid #dadce0;
        border-radius: 4px;
        text-shadow: 0 0 #6e6d7a;
        letter-spacing: 0.2px;
    }

    .ant-input[placeholder] {
        color: gray;
        text-shadow: 0 0 #BBBBBB;
    }

    .ant-form-item-label {
        padding: 0 0 2px;
        text-align: left;
    }

    .ant-form-item {
        color: rgba(0, 0, 0, 0.85);
        font-size: 13px;
        margin-bottom: 12px;
    }

    .ant-select {
        font-size: 14px;
        letter-spacing: 0.2px;
        font-weight: 400;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 32px !important;
        padding: 1px 10px;
        color: black !important;
        letter-spacing: 0.2px;
        // text-shadow: 0 0 #6e6d7a;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        font-size: 13px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        padding: 0;
        line-height: 27px;
        transition: all 0.3s;

        text-shadow: 0 0 #BBBBBB;
    }

    .ant-picker {
        color: #6e6d7a !important;
        font-size: 13px;
        padding: 5px 11px 3px;
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        letter-spacing: 0.2px;
    }

    .ant-picker-input>input {
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        color: #6e6d7a;
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
        flex: auto;
        min-width: 1px;
        height: auto;
        padding: 0;
        background: transparent;
        border: 0;
        text-shadow: 0 0 #6e6d7a;
        letter-spacing: 0.2px;
    }

    .ant-upload {
        .ant-btn {
            background-image: none;
            border: 1px solid transparent;
            height: 28px;
            padding: 3px 15px;
            font-size: 13px;
            border-radius: 4px;
            color: rgba(0, 0, 0, 0.85);
            border-color: #d9d9d9;
            background: #fff;
        }
    }

}



.ant-transfer-operation {
    .ant-btn {
        color: white;
        display: flex;
        align-items: center;
        height: 24px;
        min-width: 80px !important;
        position: relative;
        padding: 0;
        background-color: #045140;

        &:disabled {
            background-color: #F5F5F5;
            color: gray;
        }

        span {
            display: block;
            position: absolute;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            font-weight: bold;
        }
    }
}
.ck-toolbar__items{
    flex-wrap: wrap !important;
}
.ck-content{
    min-height: 200px;
}