.lesson__quiz__block {
  .quiz__select {
    .ant-select-selection-overflow-item {
      width: 100%;
      display: block;
      .ant-select-selection-item {
        justify-content: space-between;
        padding-right: 10px;
        white-space: pre-wrap;
        height: auto;
        .ant-select-selection-item-content {
          white-space: pre-wrap;
        }
        .ant-select-selection-item-remove {
        }
      }
    }
  }
}
