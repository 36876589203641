.user-view-photo-wrapper {
    .ant-image-mask{
        border-radius: 50%!important;
    }
    .user-view-photo{
        width: 200px;
        border-radius: 50%;
        margin: 0 auto;
    }
}
.users{
    td{
        svg{
            margin: 0!important;
        }
    }
}
// @media only screen and (max-width)


@media only screen and (max-width:545px) {
    .user-view-photo-wrapper{

        .user-view-photo{
            width: 150px;
        }
    }
    .password-wrapper{
        .ant-btn{
            padding: 4px 8px!important;
            svg{
                width: 15px!important;
            }
        }
    }

}
@media only screen and (max-width:499px) {
        .permisson-tab{
        .ant-form{
            .ant-btn{
                margin-top: 53px!important;
            }
        }
    }
}