.custom_form_label {
    font-size: 13px;
    font-weight: 400;
    color: #8c8c8c;
    margin-right: 10px;
}
.custom_form_label_crud {
    // font-size: 13px;
    // font-weight: 500;
    // color: #72777D;
    // margin-right: 10px;
    // letter-spacing: .01785714em;
    // line-height: 1.25rem;
    // white-space: nowrap;
    // position: relative;
    // display: inline-flex;
    // align-items: center;
    max-width: 100%;
    // height: 32px;
    color: #959595;
    // color: #666768;
    font-size: 14px;
    font-weight: 5\400;
    // font-family: "Product Sans",Arial,sans-serif !important;
}