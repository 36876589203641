.freeVideoModal {
  width: 80% !important;
  height: 80vh;
  // @include xxl{
  //   height: 70vh;
  // }
  // @include xl{
  //   height: 60vh;
  // }
  // @include md{
  //   height: 40vh;
  // }
  // @include sm{
  //   height: 30vh;
  // }
  .ant-modal-content {
    height: 100%;
    .ant-modal-body {
      height: 90%;
    }
  }
}
