.user-permissions{
    .ant-transfer{
        flex-direction: row;
    }
    
   
}
@media only screen and (max-width:992px) {
    .user-permissions{
        .ant-transfer{
            flex-direction: column!important;
            gap: 30px;
        }
        .ant-transfer-operation{
            transform: rotate(90deg);
        }
    }
}