.lesson__blocks {
  .lesson__blocks__wrapper {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    .lesson__blocks__list {
      list-style-type: none;
      border: 1px solid green;
      padding: 10px;
      border-radius: 10px;
      &.lesson__blocks__left {
        width: 200px;
        position: sticky;
        top: 80px;
      }
      &.lesson__blocks__right {
        width: 100%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
      }
      .lesson__block__item {
        padding: 20px 10px;
        background-color: #ffffff;
        box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.078);
        border-radius: 10px;
        & + .lesson__block__item {
          margin-top: 10px;
        }
      }
      .lesson__block__element {
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.399);
        border-radius: 10px;
        position: relative;
        & + .lesson__block__element {
          // margin-top: 30px;
        }
        .lesson__block__remover {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 20px;
          height: 20px;
          background-color: red;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          svg {
            width: 15px;
            height: 15px;
          }
        }
        .lesson__code__block {
        }
      }
    }
    .saveButton {
      background-color: #045140;
      color: white;
      padding: 7px 20px;
      border-radius: 10px;
      margin-top: 20px;
      display: block;
      margin-left: auto;
    }
  }
}
