.header-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
padding-right: 15px;
width: 100%;
  .menu-icon{
    display: none;
    font-size: 20px;
    margin: 3px 15px;
}
.language-role-wrapper{
    display: flex;
    flex-direction: row;
}
.desktop-lunguage-role{
  display: flex;
  align-items: center;
}
}



@media only screen and (max-width: 768px) {
  .ant-drawer-content-wrapper{
    // width: 50%!important;
    .ant-drawer-body{
      gap: 10px;
      display: flex;
      flex-direction:column;
    }
  }
  .header-wrapper{
    .desktop-lunguage-role{
      display: none;
    }
   
    .collapse-btn {
      display: none !important;
    }
    .menu-icon{
      display: inline-block;
    }
    .navbar-mobile{
      width: 100vw;
      height: 150vh;
      background: rgba(0,0,0,0.3);
      backdrop-filter: 3px;
    }
    .language-role-wrapper{
      position: absolute;
      background: #fff;
     flex-direction: column;
      margin-top: 40px;
      gap: 10px;
      z-index: 4;
      // padding: 0 15px;
      left: 0;
    }
      .active{
      width: 60%;
      height: 100%;
      background: #fff;
      // top: 64px;
    }.reactive{
      top: -200px;
    }
  }
}
