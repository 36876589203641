@tailwind base;
@tailwind components;
@tailwind utilities;

.main-card-styles {
    box-shadow: 0px 0px 6px 2px rgba(182, 182, 182, 0.1);
}

.main-card {
    @apply bg-white p-4 main-card-styles rounded-md;
}