@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-Thin.ttf") format("ttf");
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-ExtraLight.ttf") format("ttf");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-Light.ttf") format("ttf");
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-Regular.ttf") format("ttf");
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-Medium.ttf") format("ttf");
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-SemiBold.ttf") format("ttf");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-Bold.ttf") format("ttf");
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-ExtraBold.ttf") format("ttf");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/Inter/Inter/static/Inter-Black.ttf") format("ttf");
  }