.main-loader-wrapper {
  height: 100vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  &.main-loader-wrapper-transparent {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999999999999999999999999999999 !important;
  }
}

.mian-loader-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  border-radius: 100%;
  opacity: 0;
  border: 5px solid #045140;
  animation: anim 2s infinite linear;
}

@keyframes anim {
  0% {
    opacity: 0;
    width: 0vmin;
    height: 0vmin;
  }
  10% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    width: 100vmin;
    height: 100vmin;
  }
}

.mian-loader-item:nth-child(1) {
  animation-delay: 100ms;
}
.mian-loader-item:nth-child(2) {
  animation-delay: 300ms;
}
.mian-loader-item:nth-child(3) {
  animation-delay: 400ms;
}
.mian-loader-item:nth-child(4) {
  animation-delay: 500ms;
}
.mian-loader-item:nth-child(5) {
  animation-delay: 800ms;
}
.mian-loader-item:nth-child(6) {
  animation-delay: 1000ms;
}
.mian-loader-item:nth-child(7) {
  animation-delay: 1200ms;
}
.mian-loader-item:nth-child(8) {
  animation-delay: 1400ms;
}
.mian-loader-item:nth-child(9) {
  animation-delay: 1600ms;
}
