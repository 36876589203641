.login-glassmorphism {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.8);
}


.login-box .ant-input {
    margin: 0;
    padding: 6px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #045140;
    border-radius: 4px;
}

.login-box .ant-form-item .ant-form-item-label>label {
    color: #045140;
    font-size: 15px;
    font-weight: 400;
}

.login-box .ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 9px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #045140;
    border-radius: 6px;
    transition: all 0.2s;
}